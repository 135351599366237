<template>
  <div>
    <dynamic-data-table
      table-title="Permissions"
      :table-records="tableSettingsPermissions"
      :table-headers="permissionHeaders"
      :can-edit="true"
      :can-delete="true"
      :is-loading="tablePermissionsStatus === 'loading'"
      @deleteItem="setupDelete"
      @editItem="setupEdit"
    >
      <template #item.role="{ value }">
        <td>{{ value?.name }}</td>
      </template>
      <template #item.create="{ value, permissionId }">
        <v-simple-checkbox :value="value" disabled :ripple="false" />
      </template>
      <template #item.update="{ value }">
        <v-simple-checkbox :value="value" disabled :ripple="false" />
      </template>
      <template #item.read="{ value }">
        <v-simple-checkbox :value="value" disabled :ripple="false" />
      </template>
      <template #item.delete="{ value }">
        <v-simple-checkbox :value="value" disabled :ripple="false" />
      </template>
      <template #item.configure="{ value }">
        <v-simple-checkbox :value="value" disabled :ripple="false" />
      </template>
      <template slot="table-actions">
        <v-dialog
          key="add-edit-permission"
          v-model="permissionDialog"
          max-width="500px"
          @click:outside="closePermissionDialogs"
          @keydown.esc="closePermissionDialogs"
        >
          <template #activator="{ on, attrs }">
            <v-tooltip left>
              <template #activator="{ on: onTooltip }">
                <v-icon dense class="ant-icon" v-on="{ ...on, ...onTooltip }">
                  mdi-plus
                </v-icon>
              </template>
              <span>New permission</span>
            </v-tooltip>
          </template>
          <v-card>
            <v-card-title class="justify-center text-uppercase headline">
              {{ permissionItem.id ? 'Edit' : 'Add' }} Permission
            </v-card-title>
            <v-divider />
            <div class="px-10 pb-5">
              <ant-input label="Team">
                <template #input-field>
                  <v-select
                    v-if="!permissionItem.id"
                    v-model="permissionItem.role"
                    :items="nonConfiguredRoles"
                    item-text="name"
                    item-value="id"
                    hide-details
                    placeholder="Team"
                    return-object
                    dense
                    filled
                    single-line
                  />
                  <v-text-field
                    v-else
                    disabled
                    :value="
                      tableSettingsTeams.find(
                        (team) => team.id === permissionItem.group
                      ).name
                    "
                    hide-details
                    dense
                    filled
                    single-line
                  />
                </template>
              </ant-input>
              <v-row>
                <v-col cols="12" sm="6" md="4">
                  <v-checkbox
                    v-model="permissionItem.create"
                    class="mx-2"
                    label="Create"
                    hide-details
                  />
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <v-checkbox
                    v-model="permissionItem.read"
                    class="mx-2"
                    label="Read"
                    hide-details
                  />
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <v-checkbox
                    v-model="permissionItem.update"
                    class="mx-2"
                    label="Update"
                    hide-details
                  />
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <v-checkbox
                    v-model="permissionItem.delete"
                    class="mx-2"
                    label="Delete"
                    hide-details
                  />
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <v-checkbox
                    v-model="permissionItem.configure"
                    class="mx-2"
                    label="Configure"
                    hide-details
                  />
                </v-col>
              </v-row>
            </div>

            <v-card-actions class="ant-dialog-actions-bg ant-border-top">
              <v-spacer />
              <v-btn color="error" small text @click="closePermissionDialogs">
                Cancel
              </v-btn>
              <v-btn
                color="primary"
                small
                elevation="0"
                @click="savePermission"
              >
                Save
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </template>
    </dynamic-data-table>
    <delete-dialog
      :dialog="permissionDeleteDialog"
      title="Are you sure you want to remove all permissions for this team"
      @closeDialog="closePermissionDialogs"
      @deleteAction="deletePermission"
    />
  </div>
</template>

<script>
import DynamicDataTable from '@/components/DynamicDataTable';
import { mapGetters } from 'vuex';
import DeleteDialog from '@/components/DeleteDialog';
import AntInput from '@/components/AntInput.vue';

export default {
  name: 'TablePermissions',
  components: { AntInput, DeleteDialog, DynamicDataTable },
  data: () => {
    return {
      permissionHeaders: [
        {
          text: 'team',
          value: 'role',
          hasSlot: true,
        },
        {
          text: 'create',
          value: 'create',
          hasSlot: true,
        },
        {
          text: 'read',
          value: 'read',
          hasSlot: true,
        },
        {
          text: 'update',
          value: 'update',
          hasSlot: true,
        },
        {
          text: 'delete',
          value: 'delete',
          hasSlot: true,
        },
        {
          text: 'configure',
          value: 'configure',
          hasSlot: true,
        },
        { text: 'Actions', value: 'actions', align: 'right', sortable: false },
      ],
      permissionItem: {
        create: false,
        read: false,
        update: false,
        delete: false,
      },
      permissionDialog: false,
      permissionDeleteDialog: false,
    };
  },
  computed: {
    ...mapGetters([
      'selectedProjectTable',
      'tableSettingsPermissions',
      'tableSettingsTeams',
      'tablePermissionsStatus',
    ]),
    nonConfiguredRoles() {
      if (this.tableSettingsPermissions.length > 0) {
        return this.tableSettingsTeams.filter((team) => {
          return (
            this.tableSettingsPermissions.find(
              (permission) => permission.role.id !== team.id
            ) && !team.is_admin
          );
        });
      } else {
        return this.tableSettingsTeams;
      }
    },
  },
  methods: {
    closePermissionDialogs() {
      this.permissionItem = Object.assign(
        {},
        {
          create: false,
          read: false,
          update: false,
          delete: false,
        }
      );
      this.permissionDialog = false;
      this.permissionDeleteDialog = false;
    },
    setupEdit(permission) {
      this.permissionItem = Object.assign({}, permission);
      this.permissionDialog = true;
    },
    setupDelete(permission) {
      this.permissionItem = Object.assign({}, permission);
      this.permissionDeleteDialog = true;
    },
    deletePermission() {
      this.$store
        .dispatch('deletePermission', {
          permissionId: this.permissionItem.id,
        })
        .then(() => {
          this.closePermissionDialogs();
        });
    },
    savePermission() {
      let data = Object.assign({}, this.permissionItem);

      let obj = {
        table_id: this.selectedProjectTable.id,
        role_id: this.permissionItem.role.id,
      };

      Object.keys(data).forEach((key, index) => {
        obj[key] = data[key];
      });

      let body = { permissions: [obj] };

      this.$store.dispatch('setPermission', { body });
      this.closePermissionDialogs();
    },
  },
};
</script>

<style scoped></style>
