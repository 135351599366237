<template>
  <div class="ant-panel flex-1 pos-rel overflow-y-auto">
    <v-toolbar flat>
      <v-toolbar-title class="flex-1"> Record History </v-toolbar-title>
      <v-icon
        v-if="recordHistoryStatus === 'success'"
        class="mr-2"
        @click="fullScreenHistory = true"
      >
        mdi-fullscreen
      </v-icon>
      <v-icon v-if="recordHistoryStatus === 'success'" @click="clearHistory">
        mdi-close
      </v-icon>
    </v-toolbar>
    <v-data-table
      v-if="recordHistoryStatus === 'success'"
      :headers="getHeaders()"
      :items="sortedHistory"
      dense
      fixed-header
      height="300"
      hide-default-footer
      :item-class="isActualRecord"
    >
      <template #[`item.user`]="{ item }">
        <td>{{ item.user_name }}</td>
      </template>
      <template #[`item.date`]="{ item }">
        <td>{{ item.revisioned }}</td>
      </template>
    </v-data-table>
    <div
      v-else
      class="d-flex justify-center align-center"
      style="height: 300px"
    >
      <ant-loading />
    </div>

    <v-dialog
      key="full-screen-history"
      v-model="fullScreenHistory"
      width="unset"
    >
      <v-data-table
        v-if="recordHistoryStatus === 'success'"
        :headers="getHeaders()"
        :items="sortedHistory"
        dense
        hide-default-footer
        :item-class="isActualRecord"
        style="padding: 10px 12px"
      >
        <template #[`item.user`]="{ item }">
          <td>{{ item.user_name }}</td>
        </template>
        <template #[`item.date`]="{ item }">
          <td>{{ item.revisioned }}</td>
        </template>
      </v-data-table>
      <div v-else class="d-flex justify-center align-center">
        <ant-loading />
      </div>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import moment from 'moment';
import AntLoading from '@/components/AntLoading';

export default {
  name: 'RecordHistory',
  components: { AntLoading },
  props: {
    record: {
      type: Object,
      required: true,
    },
  },
  data: () => {
    return {
      fullScreenHistory: false,
    };
  },
  computed: {
    ...mapGetters([
      'selectedProjectTable',
      'recordHistory',
      'recordHistoryStatus',
    ]),
    sortedHistory() {
      let history = this.recordHistory.history.filter(
        (item) => item.revisioned !== null
      );

      history.sort(
        (a, b) => moment(b.revisioned).unix() - moment(a.revisioned).unix()
      );

      let actualRecord = this.recordHistory.history.find(
        (item) => item.revisioned === null
      );

      history.unshift(actualRecord);

      return history;
    },
  },
  watch: {
    record: {
      deep: true,
      immediate: true,
      handler() {
        this.$store.dispatch('loadRecordHistory', {
          projectId: this.selectedProjectTable.project,
          tableId: this.selectedProjectTable.id,
          record: this.record,
        });
      },
    },
  },
  methods: {
    clearHistory() {
      this.$store.dispatch('resetHistory');
      this.$emit('closeHistory');
    },
    isActualRecord(item) {
      return this.sortedHistory.indexOf(item) === 0 ? 'actual' : '';
    },
    getHeaders() {
      let keys = Object.keys(this.recordHistory.record);
      let keysArray = [];

      keys.forEach((element) => {
        let label = { text: element, value: element };
        keysArray.push(label);
      });

      keysArray = keysArray.slice(1);

      keysArray.unshift({ text: 'user', value: 'user' });

      keysArray.push({ text: 'date', value: 'date' });
      return keysArray;
    },
  },
};
</script>

<style scoped lang="scss">
.history-container {
  padding: 10px 12px;
  flex: 1;
  margin-left: 5px;
  overflow: scroll;
  position: relative;
}
</style>
