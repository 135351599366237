<template>
  <v-dialog
    :value="dialog"
    width="unset"
    :max-width="maxWidth"
    v-bind="$attrs"
    @click:outside="closeDialog"
    @keydown.esc="closeDialog"
  >
    <v-card>
      <v-card-title>{{ title }}</v-card-title>
      <v-card-text v-if="description">
        {{ description }}
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn color="" text :disabled="loading" @click="closeDialog">
          Cancel
        </v-btn>
        <v-btn color="primary" text :loading="loading" @click="confirmAction">
          Confirm
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'ConfirmDialog',
  props: {
    dialog: {
      type: Boolean,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      default: undefined,
    },
    maxWidth: {
      type: String,
      default: '90%',
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    closeDialog() {
      this.$emit('closeDialog');
    },
    confirmAction() {
      this.$emit('confirmAction');
    },
  },
};
</script>

<style scoped></style>
