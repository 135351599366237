<template>
  <div class="flex-grow-1">
    <div class="ant-glass-background flex-grow-1 pa-5">
      <v-toolbar flat style="background: transparent">
        <v-toolbar-title style="flex: 1"> SQL Query Maker </v-toolbar-title>
        <v-spacer />
        <v-btn color="primary" small @click="queryTable()"> Query </v-btn>
      </v-toolbar>
      <div v-for="(column, index) in query.columns" :key="index" class="d-flex">
        <v-checkbox
          v-model="column.active"
          :label="column.name"
          hide-details
          class="mr-4 flex-grow-1"
        />
        <v-select
          v-model="column.condition.operator"
          :disabled="!column.active"
          :items="operators"
          hide-details
          label="Operator"
          clearable
          class="mr-4"
        />
        <v-text-field
          v-model="column.condition.value"
          :disabled="!column.active"
          label="Value"
          clearable
          hide-details
        />
      </div>
    </div>

    <div class="ant-glass-background mt-2">
      <dynamic-data-table
        table-title="Query Results"
        :table-headers="queryTableHeaders"
        :table-columns="tableColumns"
        :table-records="queryRecords"
        :is-loading="queryRecordsStatus === 'loading'"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import DataHelper from '@/services/data-helper';
import DynamicDataTable from '@/components/DynamicDataTable';

export default {
  name: 'TableQuery',
  components: { DynamicDataTable },
  data: () => {
    return {
      query: {
        columns: [],
      },
      operators: ['=', '>', '<', '>=', '<=', '<>'],
    };
  },
  computed: {
    ...mapGetters([
      'tableColumns',
      'selectedProjectTable',
      'queryRecords',
      'queryRecordsStatus',
    ]),
    queryTableHeaders() {
      return DataHelper.getTableColumns(this.tableColumns, false, false);
    },
  },
  mounted() {
    this.tableColumns.forEach((column) => {
      this.query.columns.push({
        name: column.name,
        active: false,
        condition: {
          operator: undefined,
          value: undefined,
        },
      });
    });
  },
  methods: {
    queryTable() {
      let body = {
        columns: [],
      };
      this.query.columns
        .filter((column) => column.active)
        .forEach((column) => {
          let tmp = Object.assign({}, column);
          body.columns.push(tmp);
        });

      body.columns.forEach((column) => {
        delete column.active;
        if (
          column.condition.operator === undefined ||
          column.condition.value === undefined ||
          column.condition.operator === null ||
          column.condition.value === null
        ) {
          delete column.condition;
        }
      });

      this.$store.dispatch('fetchTableQueryRecords', {
        projectId: this.selectedProjectTable.project,
        tableId: this.selectedProjectTable.id,
        body,
      });
    },
  },
};
</script>

<style scoped lang="scss"></style>
