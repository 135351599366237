<template>
  <div
    class="project-table-container"
    :class="{ active: isActive }"
    @click="$emit('table-click')"
  >
    <v-tooltip v-if="isMasterTable" bottom>
      <template #activator="{ on, attrs }">
        <v-icon color="primary" dark v-bind="attrs" class="mr-2" v-on="on">
          mdi-database-arrow-up
        </v-icon>
      </template>
      <span>Master table</span>
    </v-tooltip>

    <div class="table-header">
      {{ name }}
    </div>
    <v-icon
      v-if="
        ($can('access', 'project-table-settings') || canConfigure) &&
        !isMasterTable
      "
      dense
      class="table-settings-button"
      @click.stop="$emit('settings-click')"
    >
      mdi-cog
    </v-icon>
  </div>
</template>

<script>
export default {
  name: 'TablesSidebarItem',
  props: {
    table: {
      type: Object,
      default: () => ({}),
    },
    isActive: {
      type: Boolean,
      require: true,
    },
  },
  computed: {
    isMasterTable() {
      return this.table?.is_master_table;
    },
    canConfigure() {
      return this.table?.permissions?.configure;
    },
    name() {
      return this.table?.name;
    },
  },
};
</script>

<style scoped lang="scss">
.project-table-container {
  padding: 10px;
  display: flex;
  cursor: pointer;
  transition: 200ms ease-out;

  .table-header {
    flex: 1;
    font-size: 14px;
    text-overflow: ellipsis;
    transition: 200ms ease-out;
  }

  &:hover {
    background: #f2f2f2;

    .table-settings-button {
      opacity: 1;
    }

    .table-header {
      transform: translateX(10px);
    }
  }

  &.active {
    background: #f2f2f2;

    .table-settings-button {
      opacity: 1;
    }

    .table-header {
      transform: translateX(10px);
    }
  }

  .table-settings-button {
    transition: 200ms;
    opacity: 0;
  }
}
</style>
