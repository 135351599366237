<template>
  <div>
    <div v-if="['boolean', 'integer', 'float'] || item[columnName]">
      <div v-if="type === 'document'">
        <document-cell
          :item="item"
          :column="columnName"
          :project-id="projectId"
          :table-id="tableId"
          @previewFile="onPreviewFile"
        />
      </div>
      <div v-else-if="type === 'link'">
        <a target="_blank" :href="item[columnName]">{{ item[columnName] }}</a>
      </div>
      <div v-else-if="type === 'table'">
        {{ tableName }}
      </div>
      <v-simple-checkbox
        v-else-if="type === 'boolean'"
        disabled
        :ripple="false"
        :value="getBooleanValue(item[columnName])"
      />
      <div v-else>
        {{ item[columnName] }}
      </div>
    </div>
    <div v-else>-</div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import DocumentCell from '@/components/DynamicDataTable/DocumentCell.vue';
import DataHelper from '@/services/data-helper';

export default {
  name: 'TableViewCell',
  components: { DocumentCell },
  props: {
    item: {
      type: Object,
      required: true,
    },
    columnName: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
    projectId: {
      type: String,
      required: true,
    },
    tableId: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapGetters(['projectTables']),
    tableName() {
      if (this.type === 'table') {
        return this.projectTables.find(
          (t) => t.id === this.item[this.columnName]
        )?.name;
      }
      return '';
    },
  },
  methods: {
    getBooleanValue(value) {
      return DataHelper.getBooleanValue(value);
    },
    onPreviewFile(value) {
      this.$emit('previewFile', value);
    }
  },
};
</script>

<style scoped></style>
