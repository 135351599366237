<template>
  <div class="d-flex">
    <v-menu v-if="selectedRevision.reason" open-on-hover bottom offset-y>
      <template #activator="{ on: info, attrs: infoAttrs }">
        <v-btn icon small exact v-bind="infoAttrs" class="ma-1" v-on="info">
          <v-icon> mdi-information-outline </v-icon>
        </v-btn>
      </template>
      <div class="pa-2 ant-glass-background">
        <div class="d-flex flex-column">
          <div>Reason:</div>
          <div>
            {{ selectedRevision.reason || 'No reason' }}
          </div>
        </div>
      </div>
    </v-menu>
    <div v-else class="pr-2"></div>
    <div class="pr-2">
      <div style="font-size: 12px" class="font-italic">
        {{ formatDate(selectedRevision.timestamp) }}
      </div>
      <div style="font-size: 10px">
        <span>Version: </span>
        <span>{{ selectedRevision.version }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import moment from 'moment';

export default {
  name: 'TableCompareRevisionItem',
  props: {
    revisionId: {
      type: String,
      default: '',
    },
  },
  computed: {
    ...mapGetters(['tableRevisions']),
    selectedRevision() {
      return this.tableRevisions.find(
        (revision) => revision.id === this.revisionId
      );
    },
  },
  methods: {
    formatDate(date) {
      return moment(date).format('YYYY-MM-DD HH:mm');
    },
  },
};
</script>

<style scoped></style>
