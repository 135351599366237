<template>
  <div class="ant-glass-background">
    <dynamic-data-table
      table-title="Columns"
      :table-records="tableSettingsColumns"
      :table-headers="columnHeaders"
      :is-loading="tableColumnStatus === 'loading'"
      :can-edit="true"
      :can-delete="true"
      @deleteItem="setupDelete"
      @editItem="setupEdit"
    >
      <template #item.is_unique="{ value, rowId, item }">
        <v-simple-checkbox :value="Boolean(value)" disabled :ripple="false" />
      </template>
      <template #item.has_relation="{ value, rowId, item }">
        <v-simple-checkbox
          :value="hasColumnRelation(rowId)"
          disabled
          :ripple="false"
        />
      </template>
      <template #item.options_value="{ value }">
        <div class="d-flex flex-column">
          <v-chip v-for="(option, index) in value" :key="index" class="my-1">
            {{ option.length < 20 ? option : `${option.substring(0, 20)}…` }}
          </v-chip>
        </div>
      </template>
      <template #item.required="{ value }">
        <v-simple-checkbox :value="Boolean(value)" disabled :ripple="false" />
      </template>
      <template #table-actions>
        <v-dialog
          key="table-column"
          v-model="columnDialog"
          max-width="500px"
          @click:outside="closeColumnDialogs"
        >
          <template #activator="{ on, attrs }">
            <v-tooltip left>
              <template #activator="{ on: onTooltip }">
                <v-icon dense class="ant-icon" v-on="{ ...on, ...onTooltip }">
                  mdi-plus
                </v-icon>
              </template>
              <span>New column</span>
            </v-tooltip>
          </template>
          <v-card>
            <v-card-title class="justify-center text-uppercase headline">
              {{ columnItem.id ? 'Edit' : 'Add' }} Column
            </v-card-title>
            <v-divider />
            <div class="px-10 pb-5">
              <ant-input label="Type">
                <template #input-field>
                  <v-select
                    v-model="columnItem.type"
                    :items="columnTypes"
                    placeholder="type"
                    :disabled="columnItem.id"
                    filled
                    dense
                    single-line
                    hide-details
                  />
                </template>
              </ant-input>
              <div v-if="columnItem.type" class="d-flex">
                <v-switch
                  v-model="columnItem.is_unique"
                  label="unique"
                  :disabled="columnItem.id"
                  dense
                  hide-details
                  inset
                />
                <v-spacer />
                <v-switch
                  v-model="columnItem.required"
                  label="required"
                  dense
                  hide-details
                  inset
                />
              </div>
              <div class="d-flex">
                <ant-input label="Name">
                  <template #input-field>
                    <v-text-field
                      v-model="columnItem.name"
                      placeholder="name"
                      filled
                      dense
                      single-line
                      hide-details
                      :disabled="columnItem.type === 'sbscode'"
                    />
                  </template>
                </ant-input>
                <ant-input label="Order" class="ml-2">
                  <template #input-field>
                    <v-text-field
                      v-model="columnItem.order"
                      type="number"
                      label="order"
                      filled
                      dense
                      single-line
                      hide-details
                    />
                  </template>
                </ant-input>
              </div>
              <ant-input label="Hint" is-optional>
                <template #input-field>
                  <v-text-field
                    v-model="columnItem.hint"
                    placeholder="hint"
                    filled
                    dense
                    single-line
                    hide-details
                  />
                </template>
              </ant-input>
              <div
                v-if="
                  columnItem.type &&
                  columnItem.type !== 'document' &&
                  !columnItem.is_unique
                "
                class="d-flex"
              >
                <ant-input label="Default value">
                  <template #input-field>
                    <v-text-field
                      v-if="columnItem.type === ('integer' | 'float')"
                      v-model="columnItem.default_value"
                      placeholder="default value"
                      type="number"
                      filled
                      dense
                      single-line
                      hide-details
                    />
                    <v-text-field
                      v-if="columnItem.type === 'boolean'"
                      v-model="columnItem.default_value"
                      placeholder="default value"
                      type="number"
                      :min="0"
                      clearable
                      :max="1"
                      :rules="[rules.boolean]"
                      filled
                      dense
                      single-line
                      hide-details
                    />
                    <v-text-field
                      v-else
                      v-model="columnItem.default_value"
                      placeholder="default value"
                      type="text"
                      filled
                      dense
                      single-line
                      hide-details
                    />
                  </template>
                </ant-input>
                <ant-input
                  v-if="columnItem.type === 'dropdown'"
                  label="Options"
                  class="ml-2"
                >
                  <template #input-field>
                    <v-combobox
                      v-model="columnItem.options_value"
                      placeholder="options"
                      :disabled="columnItem.id !== undefined"
                      multiple
                      chips
                      deletable-chips
                      filled
                      dense
                      single-line
                      hide-details
                      small-chips
                    />
                  </template>
                </ant-input>
              </div>
            </div>

            <v-card-actions class="ant-dialog-actions-bg ant-border-top">
              <v-spacer />
              <v-btn color="error" small text @click="closeColumnDialogs">
                Cancel
              </v-btn>
              <v-btn color="primary" small elevation="0" @click="saveColumn">
                Save
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </template>
    </dynamic-data-table>
    <delete-dialog
      :dialog="columnDeleteDialog"
      :title="`Are you sure you want to delete '${columnItem.name}' from this table`"
      @closeDialog="closeColumnDialogs"
      @deleteAction="deleteColumn"
    />
  </div>
</template>

<script>
import DynamicDataTable from '@/components/DynamicDataTable';
import { mapGetters } from 'vuex';
import DeleteDialog from '@/components/DeleteDialog';
import AntInput from '@/components/AntInput.vue';

export default {
  name: 'TableColumns',
  components: { AntInput, DeleteDialog, DynamicDataTable },
  data: () => {
    return {
      columnHeaders: [
        {
          text: 'name',
          value: 'name',
        },
        {
          text: 'type',
          value: 'type',
        },
        {
          text: 'unique',
          value: 'is_unique',
          hasSlot: true,
        },
        {
          text: 'has relation',
          value: 'has_relation',
          hasSlot: true,
        },
        {
          text: 'hint',
          value: 'hint',
        },
        {
          text: 'default_value',
          value: 'default_value',
        },
        {
          text: 'options_value',
          value: 'options_value',
          hasSlot: true,
        },
        {
          text: 'required',
          value: 'required',
          hasSlot: true,
        },
        {
          text: 'order',
          value: 'order',
        },
        { text: 'Actions', value: 'actions', align: 'right', sortable: false },
      ],
      columnTypes: [
        'text',
        'text-field',
        'integer',
        'boolean',
        'date',
        'link',
        'email',
        'dropdown',
        'document',
        'table',
        'float',
        'sbscode',
      ],
      columnItem: {
        order: '',
        name: '',
        type: '',
        default_value: '',
        options_value: '',
        hint: '',
        required: false,
      },
      columnDialog: false,
      columnDeleteDialog: false,
      rules: {
        boolean: (value) =>
          value === ('' || null) ||
          parseInt(value) === 0 ||
          parseInt(value) === 1 ||
          'Value needs to be 0 or 1',
      },
    };
  },
  computed: {
    ...mapGetters([
      'selectedProjectTable',
      'tableSettingsColumns',
      'tableColumnStatus',
      'tableSettingsRelations',
    ]),
  },
  watch: {
    'columnItem.type'(value) {
      if (value === 'sbscode') {
        this.columnItem.name = 'SBS';
      } else {
        if (this.columnItem.name === 'SBS') {
          this.columnItem.name = '';
        }
      }
    },
  },
  methods: {
    hasColumnRelation(rowId) {
      return (
        this.tableSettingsRelations.find(
          (relation) => relation?.column?.id === rowId
        ) !== undefined
      );
    },
    closeColumnDialogs() {
      this.columnItem = Object.assign(
        {},
        {
          order: '',
          name: '',
          type: '',
          default_value: '',
          options_value: '',
          hint: '',
          required: false,
        }
      );
      this.columnDialog = false;
      this.columnDeleteDialog = false;
    },
    setupEdit(column) {
      this.columnItem = Object.assign({}, column);
      this.columnDialog = true;
    },
    setupDelete(column) {
      this.columnItem = Object.assign({}, column);
      this.columnDeleteDialog = true;
    },
    deleteColumn() {
      let body = {
        project: {
          id: this.selectedProjectTable.project,
        },
        table: {
          id: this.selectedProjectTable.id,
        },
      };

      this.$store
        .dispatch('deleteColumn', { columnId: this.columnItem.id, body })
        .then(() => {
          this.closeColumnDialogs();
        });
    },
    saveColumn() {
      // update column with id
      let data = Object.assign({}, this.columnItem);

      let body = {
        table: this.selectedProjectTable.id,
      };

      Object.keys(data).forEach((key, index) => {
        body[key] = data[key];
      });

      body.required = !!body.required;

      if (this.columnItem.id) {
        delete body.options_value;
        delete body.type;
        delete body.is_unique;

        body.required = !!body.required;

        // update column
        this.$store
          .dispatch('updateColumn', { columnId: this.columnItem.id, body })
          .then(() => {
            this.closeColumnDialogs();
          });
      } else {
        if (this.columnItem.is_unique) {
          delete body.default_value;
        }
        // create column
        this.$store.dispatch('createColumn', { body }).then(() => {
          this.closeColumnDialogs();
        });
      }
    },
  },
};
</script>

<style scoped></style>
