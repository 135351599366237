<template>
  <div class="d-flex">
    <div>
      <panel-resizable
        v-if="tablesSidebarShown"
        side="left"
        class="ant-glass-background full-height ant-border-right overflow-hidden"
        style="border-radius: 0"
        :min-width="320"
        :default-width="400"
        :collapsible="false"
        @collapse="closeTableSidebar"
      >
        <div class="d-flex flex-column flex-grow-1 overflow-hidden">
          <div>
            <v-text-field
              v-model="tableSearch"
              class="mb-2"
              flat
              filled
              dense
              clearable
              hide-details
              prepend-inner-icon="mdi-magnify"
              placeholder="Type here to search"
              style="flex: 0"
            ></v-text-field>
            <v-subheader class="px-2 mb-2" style="height: auto">
              <div class="text-subtitle-2 py-1">Tables</div>
              <v-spacer />
              <v-tooltip left>
                <template #activator="{ on }">
                  <v-icon
                    dense
                    class="ant-icon mr-2"
                    :color="isQueryMakerEnabled ? 'primary' : ''"
                    v-on="on"
                    @click="toggleQueryMaker"
                  >
                    {{ 'mdi-database-search' }}
                  </v-icon>
                </template>
                <span>Query maker</span>
              </v-tooltip>
              <v-tooltip v-if="hasConfigureTables" left>
                <template #activator="{ on }">
                  <v-icon
                    dense
                    class="ant-icon mr-2"
                    v-on="on"
                    @click="toggleConfigTable = !toggleConfigTable"
                  >
                    {{ toggleConfigTable ? 'mdi-table' : 'mdi-table-cog' }}
                  </v-icon>
                </template>
                <span>{{
                  toggleConfigTable
                    ? 'Display project tables'
                    : 'Display config tables'
                }}</span>
              </v-tooltip>
              <v-tooltip v-if="$can('create', 'project-table')" left>
                <template #activator="{ on }">
                  <v-icon
                    dense
                    class="ant-icon"
                    v-on="on"
                    @click="tableDialog = true"
                  >
                    mdi-plus
                  </v-icon>
                </template>
                <span>New table</span>
              </v-tooltip>
            </v-subheader>
          </div>
          <div
            v-if="projectTables.length"
            class="d-flex flex-column flex-grow-1 px-2 overflow-y-auto flex-scroll-height overflow-x-auto"
          >
            <tables-sidebar-item
              v-for="table in tables"
              :key="table.id"
              :table="table"
              :is-active="
                isQueryMakerEnabled
                  ? isQueryTableSelected(table)
                  : table === selectedProjectTable
              "
              @table-click="tableClick(table)"
              @settings-click="navigateToTableSettings(table)"
            />
          </div>
          <div
            v-if="$wait.is('project.tables.get')"
            class="d-flex justify-center align-center full-height"
          >
            <ant-loading />
          </div>
          <v-dialog
            key="add-table"
            v-model="tableDialog"
            max-width="400"
            @click:outside="tableDialog = false"
            @keydown.esc="tableDialog = false"
            @keydown.enter="createTable"
          >
            <v-card>
              <v-card-title class="justify-center text-uppercase headline">
                Add table
              </v-card-title>
              <v-divider />
              <div class="px-10 pb-5">
                <ant-input label="Name" :is-optional="false">
                  <template #input-field>
                    <v-text-field
                      v-model="tableItem.name"
                      counter="45"
                      placeholder="Name"
                      maxlength="45"
                      hide-details
                      dense
                      filled
                      single-line
                    />
                  </template>
                </ant-input>
              </div>

              <v-card-actions class="ant-border-top ant-dialog-actions-bg">
                <v-spacer />

                <v-btn
                  color="primary"
                  text
                  small
                  :disabled="$wait.is('table.create')"
                  @click="closeTableDialog"
                >
                  Cancel
                </v-btn>

                <v-btn
                  color="primary"
                  small
                  elevation="0"
                  :loading="$wait.is('table.create')"
                  @click="createTable"
                >
                  Save
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </div>
      </panel-resizable>
    </div>
    <div v-if="projectTables.length" class="d-flex flex-1 overflow-auto">
      <transition name="fade" mode="out-in">
        <router-view />
      </transition>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import AntLoading from '@/components/AntLoading';
import PanelResizable from '@/components/Project/PanelResizable';
import AntInput from '@/components/AntInput.vue';
import TablesSidebarItem from '@/components/Project/Tables/TablesSidebarItem.vue';

export default {
  name: 'Tables',
  components: { TablesSidebarItem, AntInput, PanelResizable, AntLoading },
  beforeRouteLeave(to, from, next) {
    this.$store.commit('set_project_table', undefined);
    this.$store.commit('open_tables_sidebar');
    next();
  },

  data: () => {
    return {
      tableSearch: null,
      tableDialog: false,
      tableItem: {},
      expanded: [0],
      toggleConfigTable: false,
    };
  },

  computed: {
    ...mapGetters({
      queryTables: 'tablesQuery/selectedTables',
      projects: 'projects',
      project: 'project',
      projectTables: 'projectTables',
      hasConfigureTables: 'hasConfigureTables',
      selectedProjectTable: 'selectedProjectTable',
      tablesSidebarShown: 'tablesSidebarShown',
    }),

    tables() {
      let tables = [...this.projectTables];
      if (this.toggleConfigTable) {
        tables = tables.filter((table) => table.name.startsWith('CFFA'));
      } else {
        tables = tables.filter((table) => !table.name.startsWith('CFFA'));
      }
      if (this.tableSearch) {
        tables = tables.filter((table) =>
          table.name.toLowerCase().includes(this.tableSearch.toLowerCase())
        );
      }
      return tables.sort((a, b) => a.name.localeCompare(b.name));
    },
    isQueryMakerEnabled() {
      return this.$route.name === 'table-query-graph';
    },
  },
  mounted() {
    this.$store.dispatch('fetchProjectTables', this.project.id);
  },
  methods: {
    isQueryTableSelected(table) {
      return !!this.queryTables?.[table.id];
    },
    toggleQueryMaker() {
      if (this.isQueryMakerEnabled) {
        this.$router.back();
        return;
      }
      this.$router.push({ name: 'table-query-graph' });
    },
    tableClick(table) {
      if (this.isQueryMakerEnabled) {
        this.$store.dispatch('tablesQuery/toggleTable', table);
        return;
      }
      this.navigateToTable(table);
    },
    navigateToTable(table) {
      this.$store.commit('set_project_table', table);
      if (table.is_master_table) {
        const project = this.projects.find(
          (project) => project.id === table.project
        );
        this.$router.push(`/project/${project.slug}/tables/${table.id}`);
      } else {
        this.$router.push({ name: 'table', params: { tableId: table.id } });
      }
    },
    navigateToTableSettings(table) {
      this.$store.commit('set_project_table', table);
      this.$router.push({
        name: 'table-settings',
        params: { tableId: table.id },
      });
    },
    createTable() {
      if (this.tableItem.name.length > 45) {
        this.$store.commit('showNotification', {
          content: 'Character limit cannot exceed 45 characters',
          color: 'error',
        });
      } else {
        let body = {
          project: {
            id: this.project.id,
          },
          name: this.tableItem.name,
        };

        this.$store.dispatch('createTable', body).then(() => {
          this.tableDialog = false;
          this.tableItem = Object.assign({}, {});
        });
      }
    },
    closeTableSidebar() {
      // if (this.selectedProjectTable) {
      //   this.$store.commit('close_tables_sidebar');
      // }
    },
    closeTableDialog() {
      this.tableDialog = false;
      this.tableItem = Object.assign({}, {});
    },
    filterTables(tables) {
      let _tables = tables;
      if (this.tableSearch !== null) {
        _tables = _tables.filter((table) =>
          table.name.toLowerCase().includes(this.tableSearch.toLowerCase())
        );
      }
      return _tables.sort((a, b) => {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      });
    },
  },
};
</script>

<style scoped lang="scss">
.v-expansion-panels {
  .v-expansion-panel--active > .v-expansion-panel-header {
    min-height: 0;
  }

  ::v-deep .v-expansion-panel-content {
    &__wrap {
      padding: 0;
    }
  }
}
</style>
