<template>
  <div>
    <div v-if="isRevisionTheSame" class="d-flex align-center">
      <v-icon dense x-small class="mx-1" color="grey">
        mdi-circle-outline
      </v-icon>
      <table-revisions-cell
        :item="itemOld"
        :column="column"
        :type="type"
        :project-id="projectId"
        :table-id="tableId"
      />
    </div>
    <div v-else class="d-flex align-center">
      <v-menu v-model="menu" :close-on-content-click="false" offset-y>
        <template #activator="{ on, attrs }">
          <div class="d-flex" v-bind="attrs" v-on="on">
            <v-icon
              v-if="!isLoading && itemOld !== itemNew"
              dense
              x-small
              class="mx-1"
              :color="revisionIconColor"
            >
              mdi-circle
            </v-icon>
            <table-revisions-cell
              :item="itemOld || itemNew"
              :column="column"
              :type="type"
              :project-id="projectId"
              :table-id="tableId"
            />
          </div>
        </template>
        <v-card class="pa-2">
          <div class="d-flex flex-column">
            <span class="subtitle-2">{{
              oldRevision ? `Version: ${oldRevision.version}` : 'Revision'
            }}</span>
            <table-revisions-cell
              v-if="itemOld"
              class="body-2"
              :item="itemOld"
              :column="column"
              :type="type"
              :diff="revisionDiffObject"
              view-mode="current"
              :project-id="projectId"
              :table-id="tableId"
            />
          </div>
          <div class="d-flex flex-column pt-2">
            <span class="subtitle-2">{{
              newRevision ? `Version: ${newRevision.version}` : 'Compare to'
            }}</span>
            <table-revisions-cell
              v-if="itemNew"
              class="body-2"
              :item="itemNew"
              :column="column"
              :type="type"
              :diff="revisionDiffObject"
              view-mode="prev"
              :project-id="projectId"
              :table-id="tableId"
            />
          </div>
        </v-card>
      </v-menu>
    </div>
  </div>
</template>

<script>
import { diffWords, diffWordsWithSpace } from 'diff';
import TableRevisionsCell from '@/components/Project/Tables/TableRevisionsCell';
import { mapGetters } from 'vuex';

export default {
  name: 'TableRevisionsCompare',
  components: { TableRevisionsCell },
  props: {
    itemOld: {
      type: Object,
      default: () => undefined,
    },
    itemNew: {
      type: Object,
      default: () => undefined,
    },
    column: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
    isLoading: {
      type: Boolean,
      required: false,
    },
    revisionIdOld: {
      type: String,
      default: undefined,
    },
    revisionIdNew: {
      type: String,
      default: undefined,
    },
    projectId: {
      type: String,
      required: true,
    },
    tableId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      menu: false,
    };
  },
  computed: {
    ...mapGetters(['tableRevisions']),
    oldRevision() {
      if (!this.revisionIdOld) return null;
      return this.tableRevisions.find(
        (revision) => revision.id === this.revisionIdOld
      );
    },
    newRevision() {
      if (!this.revisionIdNew) return null;
      return this.tableRevisions.find(
        (revision) => revision.id === this.revisionIdNew
      );
    },
    isRevisionTheSame() {
      if (this.itemOld && this.itemNew) {
        return this.itemOld[this.column] === this.itemNew[this.column];
      }
      return false;
    },
    revisionDiffObject() {
      if (this.itemOld && this.itemNew && this.column) {
        const left = this.itemOld[this.column];
        const right = this.itemNew[this.column];
        if (left && right) {
          const diff = diffWordsWithSpace(`${left}`, `${right}`);
          const isUpdated = diff.some((i) => i['removed'] || i['added']);
          return isUpdated ? diff : undefined;
        }
      }
      return undefined;
    },
    revisionIconColor() {
      if (!this.itemNew) {
        return '#4CAF50';
      }
      if (!this.itemOld) {
        return '#FF5252';
      }
      if (this.itemOld[this.column] !== this.itemNew[this.column]) {
        return '#FFC107';
      }
      return '#525252';
    },
  },
  methods: {},
};
</script>

<style scoped></style>
