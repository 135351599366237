import { render, staticRenderFns } from "./TableCompareRevisionItem.vue?vue&type=template&id=17c9697d&scoped=true"
import script from "./TableCompareRevisionItem.vue?vue&type=script&lang=js"
export * from "./TableCompareRevisionItem.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "17c9697d",
  null
  
)

export default component.exports