<template>
  <div
    v-if="selectedProjectTable"
    class="revisions-container d-flex flex-grow-1"
  >
    <div class="flex-grow-1 d-flex flex-column full-width">
      <div class="flex-grow-1 flex-scroll-height">
        <dynamic-data-table
          ref="revision-table"
          table-title="Records"
          :table-records="revisedRecords"
          :table-headers="tableHeadersSlots"
          :table-columns="tableColumns"
          :table-id="selectedProjectTable.id"
          :project-id="selectedProjectTable.project"
          :is-loading="$wait.is('revisions.*')"
          class="ant-panel"
        >
          <template #table-buttons>
            <v-tooltip bottom>
              <template #activator="{ on, attrs }">
                <v-icon
                  class="ant-icon ml-2"
                  dense
                  v-bind="attrs"
                  v-on="on"
                  @click="
                    $router.push({
                      name: 'table',
                      params: { tableId: selectedProjectTable.id },
                    })
                  "
                  >mdi-table</v-icon
                >
              </template>
              <span>Table records</span>
            </v-tooltip>
          </template>
          <template
            v-for="header in tableHeadersSlots"
            #[`item.${header.value}`]="{ value, rowId, item }"
          >
            <div :key="`${rowId}.${header.value}`">
              <div
                v-if="tableCompare.old && tableCompare.new"
                class="d-flex align-center"
              >
                <table-revisions-compare
                  :item-old="item.old"
                  :item-new="item.new"
                  :column="header.value"
                  :type="header.type"
                  :is-loading="$wait.is('revisions.*')"
                  :revision-id-old="tableCompare.old"
                  :revision-id-new="tableCompare.new"
                  :project-id="selectedProjectTable.project"
                  :table-id="selectedProjectTable.id"
                />
              </div>
              <div v-else-if="item.old">
                <table-revisions-cell
                  :item="item.old"
                  :column="header.value"
                  :type="header.type"
                  :project-id="selectedProjectTable.project"
                  :table-id="selectedProjectTable.id"
                />
              </div>
              <div v-else-if="item.new">
                <table-revisions-cell
                  :item="item.new"
                  :column="header.value"
                  :type="header.type"
                  :project-id="selectedProjectTable.project"
                  :table-id="selectedProjectTable.id"
                />
              </div>
            </div>
          </template>

          <template #table-actions>
            <div class="compare-module">
              <table-revisions-selector
                key="revision"
                :revision-id="tableCompare.old"
                @select="onSelectRevisionOld"
                @restore="onRevisionRestoreRequest"
              />
              <table-revisions-selector
                key="compare-to"
                :revision-id="tableCompare.new"
                :before="compareDate"
                @select="onSelectRevisionNew"
              >
                <template #activate="{ on: activate, attrs: activateAttrs }">
                  <v-tooltip bottom>
                    <template #activator="{ on: tooltip, attrs: tooltipAttrs }">
                      <v-btn
                        icon
                        small
                        exact
                        v-bind="{ ...activateAttrs, ...tooltipAttrs }"
                        class="ma-1"
                        v-on="{ ...activate, ...tooltip }"
                      >
                        <v-icon color="primary"> mdi-compare </v-icon>
                      </v-btn>
                    </template>
                    <span>Choose revision to compare</span>
                  </v-tooltip>
                </template>
              </table-revisions-selector>
            </div>
            <template v-if="tableCompare.new && tableCompare.new !== '-1'">
              <v-icon class="mx-2"> mdi-compare-horizontal </v-icon>
              <div class="compare-module">
                <table-compare-revision-item :revision-id="tableCompare.new" />
                <v-tooltip bottom>
                  <template #activator="{ on, attrs }">
                    <v-icon
                      dense
                      small
                      class="mr-1 ml-2"
                      :style="{
                        opacity: tableCompareFilters.unchanged ? '1' : '0.5',
                      }"
                      v-bind="attrs"
                      v-on="on"
                      @click="
                        tableCompareFilters.unchanged =
                          !tableCompareFilters.unchanged
                      "
                    >
                      mdi-circle-outline
                    </v-icon>
                  </template>
                  <span>Show Unchanged</span>
                </v-tooltip>
                <v-tooltip bottom>
                  <template #activator="{ on, attrs }">
                    <v-icon
                      class="mx-1"
                      dense
                      small
                      :color="
                        tableCompareFilters.created ? 'success' : '#525252'
                      "
                      v-bind="attrs"
                      v-on="on"
                      @click="
                        tableCompareFilters.created =
                          !tableCompareFilters.created
                      "
                    >
                      mdi-circle
                    </v-icon>
                  </template>
                  <span>Show Created</span>
                </v-tooltip>
                <v-tooltip bottom>
                  <template #activator="{ on, attrs }">
                    <v-icon
                      dense
                      small
                      class="mx-1"
                      :color="
                        tableCompareFilters.changed ? 'warning' : '#525252'
                      "
                      v-bind="attrs"
                      v-on="on"
                      @click="
                        tableCompareFilters.changed =
                          !tableCompareFilters.changed
                      "
                    >
                      mdi-circle
                    </v-icon>
                  </template>
                  <span>Show Changed</span>
                </v-tooltip>
                <v-tooltip bottom>
                  <template #activator="{ on, attrs }">
                    <v-icon
                      dense
                      small
                      class="mx-1"
                      :color="tableCompareFilters.deleted ? 'error' : '#525252'"
                      v-bind="attrs"
                      v-on="on"
                      @click="
                        tableCompareFilters.deleted =
                          !tableCompareFilters.deleted
                      "
                    >
                      mdi-circle
                    </v-icon>
                  </template>
                  <span>Show Deleted</span>
                </v-tooltip>
                <v-tooltip bottom>
                  <template #activator="{ on, attrs }">
                    <v-btn
                      icon
                      small
                      exact
                      v-bind="attrs"
                      class="ma-1"
                      v-on="on"
                      @click="tableCompare.new = undefined"
                    >
                      <v-icon> mdi-close </v-icon>
                    </v-btn>
                  </template>
                  <span>Abort comparison</span>
                </v-tooltip>
              </div>
            </template>
          </template>

          <template #table-row-actions="{ item }">
            <v-icon small class="mr-2" @click="fetchRecordHistory(item)">
              mdi-history
            </v-icon>
          </template>
        </dynamic-data-table>
      </div>
      <div v-if="historyRecord" style="flex: 1">
        <record-history
          :record="historyRecord"
          @closeHistory="historyRecord = undefined"
        />
      </div>
    </div>
    <confirm-dialog
      :dialog="revisionRestoreDialog"
      :title="$t('system.projects.tables.revisionRestoreConfirmTitle')"
      :description="
        $t('system.projects.tables.revisionRestoreConfirmDescription')
      "
      max-width="500px"
      :loading="$wait.is('revisions.revision.restore')"
      @closeDialog="onRevisionRestoreDialogClose"
      @confirmAction="restoreRevision"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import DynamicDataTable from '@/components/DynamicDataTable';
import RecordHistory from '@/components/Project/Tables/RecordHistory';
import TableRevisionsSelector from '@/components/Project/Tables/TableRevisionsSelector';
import TableRevisionsCell from '@/components/Project/Tables/TableRevisionsCell';
import TableRevisionsCompare from '@/components/Project/Tables/TableRevisionsCompare';
import moment from 'moment';
import { isEqual } from 'lodash';
import TableCompareRevisionItem from '@/components/Project/Tables/TableCompareRevisionItem';
import ConfirmDialog from '@/components/ConfirmDialog';

export default {
  name: 'TableRevisions',
  components: {
    ConfirmDialog,
    TableCompareRevisionItem,
    RecordHistory,
    DynamicDataTable,
    TableRevisionsCell,
    TableRevisionsCompare,
    TableRevisionsSelector,
  },
  beforeRouteLeave(to, from, next) {
    this.$store.commit('reset_revision');
    next();
  },
  data: () => {
    return {
      tableCompare: {
        old: undefined,
        new: undefined,
      },
      tableCompareFilters: {
        created: true,
        unchanged: true,
        changed: true,
        deleted: true,
      },
      historyRecord: undefined,
      revisionSidebarShown: false,
      revisionRestoreId: null,
      revisionRestoreDialog: false,
    };
  },
  computed: {
    ...mapGetters([
      'projectTables',
      'selectedProjectTable',
      'tableRevisions',
      'tableRevisionRecords',
      'tableHeadersSlots',
      'tableColumns',
    ]),

    sortedRevisions() {
      return [...this.tableRevisions].sort((a, b) =>
        moment(a?.timestamp).diff(moment(b?.timestamp))
      );
    },
    compareDate() {
      if (!this.tableCompare?.old || this.tableCompare.old === '-1') {
        return moment().subtract(1, 'second').unix();
      }
      const revision = this.tableRevisions.find(
        (revision) => revision.id === this.tableCompare.old
      );
      return moment(revision?.timestamp, 'YYYY-MM-DD HH:mm:ss')
        .subtract(1, 'second')
        .unix();
    },
    revisedRecords() {
      const ignoreKeys = ['id', 'session'];
      return this.tableRevisionRecords.reduce((records, item) => {
        if (this.tableCompareFilters.created && item.new === null) {
          // Add as created
          records.push(item);
        } else if (this.tableCompareFilters.deleted && item.old === null) {
          // Add as deleted
          records.push(item);
        } else if (
          // Add as changed
          this.tableCompareFilters.changed &&
          item.old &&
          item.new &&
          Object.keys(item.old).some(
            (key) =>
              ignoreKeys.indexOf(key) === -1 && item.old[key] !== item.new[key]
          )
        ) {
          records.push(item);
        } else if (
          // Add as unchanged
          this.tableCompareFilters.unchanged &&
          item.old &&
          item.new &&
          !Object.keys(item.old).some(
            (key) =>
              ignoreKeys.indexOf(key) === -1 && item.old[key] !== item.new[key]
          )
        ) {
          records.push(item);
        }
        return records;
      }, []);
    },
  },
  watch: {
    selectedProjectTable() {
      this.fetchRevisions();
    },
    'tableCompare.new': {
      handler(id, prevId) {
        if (id !== prevId) {
          id && id !== '-1'
            ? this.setQueryParam('compareId', id)
            : this.removeQueryParam('compareId');
        }
      },
    },
    'tableCompare.old': {
      handler(id, prevId) {
        if (id !== prevId) {
          id && id !== '-1'
            ? this.setQueryParam('revisionId', id)
            : this.removeQueryParam('revisionId');
        }
      },
    },
  },
  mounted() {
    this.$store.commit('reset_revision');
    if (!this.selectedProjectTable) {
      const table = this.projectTables.find(
        (table) => table.id === this.$route.params.tableId
      );
      this.$store.commit('set_project_table', table);
    } else {
      this.fetchRevisions();
    }
    this.handleRouteQuery();
  },
  methods: {
    fetchRecordHistory(item) {
      if (item.old !== null) {
        this.historyRecord = item.old;
      } else {
        this.historyRecord = item.new;
      }
    },
    fetchRevisions() {
      this.$store.dispatch('fetchTable', {
        projectId: this.selectedProjectTable.project,
        tableId: this.selectedProjectTable.id,
        loadRecords: false,
      });
      return this.$store.dispatch('fetchTableRevisions', {
        projectId: this.selectedProjectTable.project,
        tableId: this.selectedProjectTable.id,
      });
    },
    onSelectRevisionOld(revisionId) {
      this.tableCompare.old = revisionId || '-1';
      this.$store.dispatch('compareClearDataSet', { set: 'new' });
      this.tableCompare.new = undefined;
      if (this.tableCompare.old === '-1') {
        this.$store.dispatch('setActualDataSetAsCompare', { set: 'old' });
        return;
      }

      let body = this.getRevisionBody(this.tableCompare.old);
      this.$store.dispatch('revisionOldRecords', {
        body,
        revision: this.tableRevisions.find(
          (revision) => revision.id === this.tableCompare.old
        ),
      });
    },
    onSelectRevisionNew(revisionId) {
      this.tableCompare.new = revisionId;
      if (revisionId === '-1') {
        this.$store.dispatch('setActualDataSetAsCompare', { set: 'new' });
        return;
      }

      let body = this.getRevisionBody(revisionId);
      this.$store.dispatch('revisionNewRecords', {
        body,
        revision: this.tableRevisions.find(
          (revision) => revision.id === revisionId
        ),
      });
    },
    onRevisionRestoreRequest(id) {
      this.revisionRestoreId = id;
      this.revisionRestoreDialog = true;
    },
    onRevisionRestoreDialogClose() {
      this.revisionRestoreDialog = false;
      this.revisionRestoreId = null;
    },
    async restoreRevision() {
      await this.$store.dispatch('restoreRevision', {
        revisionId: this.revisionRestoreId,
        body: {
          project: {
            id: this.selectedProjectTable.project,
          },
          table: {
            id: this.selectedProjectTable.id,
          },
        },
      });
      this.onSelectRevisionOld('-1');
      this.onRevisionRestoreDialogClose();
    },
    getRevisionBody(revisionId) {
      return {
        project: {
          id: this.selectedProjectTable.project,
        },
        table: {
          id: this.selectedProjectTable.id,
        },
        revision: revisionId,
      };
    },
    handleRouteQuery() {
      const { revisionId = '-1', compareId } = this.$route.query;
      if (revisionId) {
        this.onSelectRevisionOld(revisionId);
      }
      if (compareId) {
        this.onSelectRevisionNew(compareId);
      }
    },
    setQueryParam(param, value) {
      const { query } = this.$route;
      const updQuery = { ...query, [param]: value };
      if (!isEqual(query, updQuery)) {
        this.$router.replace({ query: updQuery });
      }
    },
    removeQueryParam(param) {
      const query = { ...this.$route.query };
      if (query[param]) {
        delete query[param];
        this.$router.replace({ query });
      }
    },
  },
};
</script>

<style scoped lang="scss">
.compare-module {
  display: flex;
  align-items: center;
  padding: 6px;
  background-color: #ecebeb;
  border-radius: 3px;
}
</style>
