<template>
  <v-text-field
    :type="type"
    :rules="rules"
    v-bind="$attrs"
    v-on="{
      ...$listeners,
      blur: () => {
        onBlur();
      },
      focus: () => {
        onFocus();
      },
    }"
  >
    <template v-if="updated && !loading" #append>
      <v-tooltip bottom>
        <template #activator="{ on: onAppendTooltip, attrs: appendAttrs }">
          <v-btn
            icon
            small
            v-bind="appendAttrs"
            v-on="onAppendTooltip"
            @click="onAppendClick"
          >
            <v-icon dense class="ant-icon"> mdi-arrow-u-left-top </v-icon>
          </v-btn>
        </template>
        <span>Rollback</span>
      </v-tooltip>
    </template>
    <template #message="{ message }">
      <v-tooltip bottom color="error" :value="message && focused">
        <template #activator="{ on, attrs }">
          <div v-bind="attrs" v-on="on" />
        </template>
        <span>{{ message }}</span>
      </v-tooltip>
    </template>
  </v-text-field>
</template>

<script>
export default {
  name: 'TableViewInput',
  props: {
    type: {
      type: String,
      default: 'text',
    },
    isUpdated: {
      type: Boolean,
      default: false,
    },
    rules: {
      type: Array,
      required: true,
    },
    loading: {
      type: [Boolean, String],
      default: false,
    },
  },
  data() {
    return {
      focused: false,
      updated: false,
    };
  },
  watch: {
    isUpdated(v) {
      this.updated = v;
    },
  },
  methods: {
    onBlur() {
      this.focused = false;
      this.$emit('blur');
    },
    onFocus() {
      this.focused = true;
      this.$emit('focus');
    },
    onAppendClick() {
      this.$emit('append-click');
    },
  },
};
</script>

<style scoped lang="scss">
::v-deep .v-text-field__details {
  height: 0;
  overflow: hidden;
  min-height: 0;
}
</style>
