<template>
  <div class="ant-glass-background">
    <dynamic-data-table
      table-title="Relations"
      :table-records="tableSettingsRelations"
      :table-headers="tableRelationHeaders"
      :is-loading="tableRelationsStatus === 'loading'"
      :can-delete="true"
      @deleteItem="setupDelete"
    >
      <template #item.column="{ value }">
        <td>{{ value.name }}</td>
      </template>
      <template #item.related_table="{ value, rowId, item }">
        <td>
          <v-tooltip v-if="item.project !== item.related_project" bottom>
            <template #activator="{ on, attrs }">
              <v-icon
                color="primary"
                dark
                v-bind="attrs"
                class="mr-2"
                v-on="on"
              >
                mdi-database-arrow-up
              </v-icon>
            </template>
            <span>Relation to master table</span>
          </v-tooltip>
          {{ value.name }}
        </td>
      </template>
      <template #item.related_column="{ value }">
        <td>{{ value ? value.name : 'id' }}</td>
      </template>
      <template #table-actions>
        <v-dialog
          key="add-relation"
          v-model="relationDialog"
          max-width="500px"
          @click:outside="closeRelationDialogs"
        >
          <template #activator="{ on, attrs }">
            <v-tooltip left>
              <template #activator="{ on: onTooltip }">
                <v-icon dense class="ant-icon" v-on="{ ...on, ...onTooltip }">
                  mdi-plus
                </v-icon>
              </template>
              <span>New relation</span>
            </v-tooltip>
          </template>
          <v-card>
            <v-card-title class="justify-center text-uppercase headline">
              Add relation
            </v-card-title>
            <v-divider />
            <div class="px-10 pb-5">
              <ant-input label="Column">
                <template #input-field>
                  <v-autocomplete
                    v-model="relationItem.column"
                    :items="columnsWithoutRelations"
                    item-text="name"
                    item-value="id"
                    return-object
                    placeholder="column"
                    hide-details
                    filled
                    single-line
                    dense
                  />
                </template>
              </ant-input>
              <ant-input label="related table">
                <template #input-field>
                  <v-autocomplete
                    v-model="relationItem.related_table"
                    :disabled="!relationItem.column"
                    :items="relatableTables"
                    item-text="name"
                    item-value="id"
                    return-object
                    label="related table"
                    hide-details
                    filled
                    single-line
                    dense
                    @change="fetchTableColumns"
                  />
                </template>
              </ant-input>
              <ant-input label="related column">
                <template #input-field>
                  <v-autocomplete
                    v-model="relationItem.related_column"
                    :disabled="!relationItem.related_table"
                    :items="relatedTableColumns"
                    item-text="name"
                    item-value="id"
                    return-object
                    label="related column"
                    hide-details
                    filled
                    single-line
                    dense
                  />
                </template>
              </ant-input>
            </div>

            <v-card-actions>
              <v-spacer />
              <v-btn color="primary" text @click="closeRelationDialogs">
                Cancel
              </v-btn>
              <v-btn color="primary" @click="saveRelation"> Save </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </template>
    </dynamic-data-table>
    <delete-dialog
      :dialog="relationDeleteDialog"
      :title="`Are you sure you want to remove relation for this column`"
      @closeDialog="closeRelationDialogs"
      @deleteAction="deleteRelation"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import DeleteDialog from '@/components/DeleteDialog';
import { getColumns } from '@/services/api/column.api';
import AntInput from '@/components/AntInput';
import DynamicDataTable from '@/components/DynamicDataTable';

export default {
  name: 'TableRelations',
  components: { DynamicDataTable, AntInput, DeleteDialog },
  data: () => {
    return {
      relationDialog: false,
      relationDeleteDialog: false,
      relationItem: {},
      tableRelationHeaders: [
        {
          text: 'column',
          value: 'column',
          hasSlot: true,
        },
        {
          text: 'related table',
          value: 'related_table',
          hasSlot: true,
        },
        {
          text: 'related column',
          value: 'related_column',
          hasSlot: true,
        },
        { text: 'Actions', value: 'actions', align: 'right', sortable: false },
      ],
      relatedTableColumns: [],
    };
  },
  computed: {
    ...mapGetters([
      'project',
      'selectedProjectTable',
      'tableSettingsRelations',
      'tableRelationsStatus',
      'tableSettingsColumns',
      'projectTables',
    ]),

    columnsWithoutRelations() {
      return this.tableSettingsColumns.filter((column) => {
        return !this.tableSettingsRelations.find(
          (relation) => relation.column.id === column.id
        );
      });
    },
    relatableTables() {
      return this.projectTables.filter(
        (table) => table.id !== this.selectedProjectTable?.id
      );
    },
  },
  methods: {
    closeRelationDialogs() {
      this.relationItem = Object.assign({}, {});
      this.relationDeleteDialog = false;
      this.relationDialog = false;
    },
    deleteRelation() {
      this.$store
        .dispatch('deleteRelation', {
          projectId: this.project.id,
          relationId: this.relationItem.id,
        })
        .then(() => {
          this.closeRelationDialogs();
        });
    },
    setupDelete(item) {
      this.relationItem = Object.assign({}, item);
      this.relationDeleteDialog = true;
    },
    saveRelation() {
      let body = {
        column: this.relationItem.column.id,
        table: this.selectedProjectTable.id,
        related_project: this.relationItem.related_table.project,
        related_table: this.relationItem.related_table.id,
        related_column: this.relationItem.related_column.id,
      };

      this.$store
        .dispatch('createRelation', { projectId: this.project.id, body })
        .then(() => {
          this.closeRelationDialogs();
        });
    },
    fetchTableColumns(table) {
      getColumns(table.project, table.id).then((columns) => {
        this.relatedTableColumns = columns;

        this.relatedTableColumns.unshift({ id: null, name: 'id' });
      });
    },
  },
};
</script>

<style scoped></style>
