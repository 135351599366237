import { render, staticRenderFns } from "./TableSettings.vue?vue&type=template&id=589ec1b4&scoped=true"
import script from "./TableSettings.vue?vue&type=script&lang=js"
export * from "./TableSettings.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "589ec1b4",
  null
  
)

export default component.exports